import { MainPage } from 'modules/main-page/pages/MainPage';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { publisherUrls } from 'constants/pagesUrls';

import { Page } from 'components/layouts/Page';

import { TAppState, wrapper } from 'sp-redux';

import { findCookieValueByName } from '../utils/findCookieValueByName';
import { timeout } from '../utils/timeout';

interface IHomePage {
  isMainPageAB?: boolean;
}

export default function Home({ isMainPageAB }: IHomePage): JSX.Element {
  const userInfo = useSelector((state: TAppState) => state.userInfo);
  const router = useRouter();
  const [tiltPhoneStyle, setTiltPhoneStyle] = useState({
    transform: 'rotateX(0deg) rotateY(0deg)',
  });
  const [tiltBookStyle, setTiltBookStyle] = useState({
    transform: 'rotateX(0deg) rotateY(0deg)',
  });

  useEffect(() => {
    const isPublisher = userInfo.is_publisher;

    if (isPublisher) {
      router.replace(publisherUrls.statistics.salesRoyalty);
    }
  }, []);

  // TODO: эту логику лучше перенести внутрь компонента MainPage
  const handleMouseMove: MouseEventHandler<HTMLDivElement> = async (
    event,
  ): Promise<void> => {
    const { clientX, clientY, currentTarget } = event;
    const { width, height, left, top } = currentTarget.getBoundingClientRect();
    const centerX = left + width / 2;
    const centerY = top + height / 2;

    const deltaX = clientX - centerX;
    const deltaY = clientY - centerY;

    const rotateX = (deltaY / height) * 10;
    const rotateY = (deltaX / width) * 20;

    setTiltPhoneStyle({
      transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    });
    await timeout(500);
    setTiltBookStyle({
      transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    });
  };

  const handleMouseLeave = (): void => {
    setTiltPhoneStyle({ transform: 'rotateX(0deg) rotateY(0deg)' });
    setTiltBookStyle({ transform: 'rotateX(0deg) rotateY(0deg)' });
  };

  return (
    <Page
      fullWidth
      handleMouseMove={handleMouseMove}
      handleMouseLeave={handleMouseLeave}
    >
      <MainPage
        tiltPhoneStyle={tiltPhoneStyle}
        tiltBookStyle={tiltBookStyle}
        isMainPageAB={isMainPageAB}
      />
    </Page>
  );
}

export const getServerSideProps: GetServerSideProps =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/require-await
  wrapper.getServerSideProps(() => async (context): Promise<any> => {
    const mainPageABCookieValue = findCookieValueByName(
      context,
      'main_page_ab',
    );

    return {
      props: { isMainPageAB: mainPageABCookieValue === 'yes' },
    };
  });
